.newsletter {
  &__field {
    display: flex;

    > div {
      margin: 0;
      padding: 0;
    }

    input, button {
      height: 52px;
      margin: 0;
      border-color: var(--color-primary-lighter) !important;
      box-shadow: none !important;
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}