@import './config/variables';

@mixin mobile {
  @media (max-width: map-get($breakpoints, 'tablet')) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: map-get($breakpoints, 'tablet')) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: map-get($breakpoints, 'desktop')) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: map-get($breakpoints, 'large-desktop')) {
    @content;
  }
}

@mixin tablet-max {
  @media (max-width: map-get($breakpoints, 'tablet')) {
    @content;
  }
}

@mixin desktop-max {
  @media (max-width: map-get($breakpoints, 'desktop')) {
    @content;
  }
}

@mixin large-desktop-max {
  @media (max-width: map-get($breakpoints, 'large-desktop')) {
    @content;
  }
}

@mixin wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: map-get($breakpoints, 'large-desktop');
  padding-left: $gap;
  padding-right: $gap;
  width: 100%;

  @include mobile {
    padding: 0 $gap;
  }

  @include tablet {
    padding: 0 ($gap * 2);
  }

  @include desktop {
    padding: 0 ($gap * 3);
  }

  @include large-desktop {
    padding: 0 ($gap * 4);
  }
}