$inputHeight: 3em;
$transition-time: .5s;
$hover-size: .25em;

@import "easings.scss";
.input {
  font-size: 1rem;
  width: 100%;

  margin: 1em 0;
  color: var(--color-primary-lighter);

  font-family: var(--default-font);

  &__label {
    display: block;
    margin-bottom: .5rem;
    color: inherit;
    font: inherit;
  }

  &__input {
    display: block;
    width: 100%;
    border: 1px solid var(--color-light-darker);
    border-radius: 4px;
    height: $inputHeight;
    border-radius: $inputHeight*.5;
    color: inherit;
    font: inherit;
    padding: .5em 1.5em;


    transition: all $transition-time $ease-out-quint;
    box-shadow: 0 0 0 currentColor;

    &--filled, &:focus {
      color: var(--color-primary-lighter);
      border-color: var(--color-primary-lighter);
    }

    &:focus {
      outline: none;


      box-shadow: $hover-size $hover-size 0 currentColor;

    }

    &:disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  textarea.input__input {
    resize: vertical;
    min-height: 7em;
    height: auto;
    border-bottom-right-radius: 0;
  }
}