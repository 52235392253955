@import '../../../styling/responsive';

@import 'easings.scss';
@import '../../../styling/grid';

$footer-gap: calc(var(--gap) * 4);

.footer {
  background: var(--color-background-dark);

  @keyframes footerAppear {
    from {
      opacity: 0;
      transform: translate3d(0, 2em, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  animation: footerAppear 1.5s $ease-out-quint forwards;
  animation-delay: 1s;
  opacity: 0;
  margin-top: $footer-gap;

  &__bottom {
    @include wrapper;

    &:before {
      content: '';
      display: block;
      border-top: 1px solid rgba(0, 0, 0, .1);
      margin-bottom: $footer-gap;
    }

    p {
      font-size: .9em;
      text-align: center;
      padding-bottom: $footer-gap;
      opacity: .75;
    }
  }

  @include tablet {
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__contact {
    @include wrapper;

    @include tablet {
      @include fixed_grid(2, calc(var(--gap)));
    }

    &_element {
      padding: $footer-gap 0;
    }
  }

  &__title {
    margin-bottom: 1em;
    width: 100%;
  }
}