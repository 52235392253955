@import './styling/config/variables';
@import './styling/responsive';

:root {
  --color-light: #{$color-light};
  --color-dark: #{$color-dark};
  --color-primary: #{$color-primary};
  --color-background: #{$color-background};
  --color-background-dark: #{$color-background-dark};

  --color-light-darker: #{$color-light-darker};
  --color-dark-darker: #{$color-dark-darker};
  --color-primary-darker: #{$color-primary-darker};
  --color-background-darker: #{$color-background-darker};

  --color-light-lighter: #{$color-light-lighter};
  --color-dark-lighter: #{$color-dark-lighter};
  --color-primary-lighter: #{$color-primary-lighter};
  --color-background-lighter: #{$color-background-lighter};

  --gap: #{$gap};
  --breakpoint-mobile: #{map-get($breakpoints, 'mobile')};
  --breakpoint-tablet: #{map-get($breakpoints, 'tablet')};
  --breakpoint-desktop: #{map-get($breakpoints, 'desktop')};
  --breakpoint-large-desktop: #{map-get($breakpoints, 'large-desktop')};
}

body {
  font-family: $default-font;
  font-size: 15px;
  background: var(--color-background);

  @include tablet {
    font-size: 16px;
  }

  @include desktop {
    font-size: 17px;
  }
}

main.content {
  @include wrapper;

  margin-top: $gap*2;
  margin-bottom: $gap*2;

  min-height: 60vh;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: var(--color-primary-darker);
  }
}