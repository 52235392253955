@import 'easings.scss';

$animation-time: .75s;
$appear-delay: .5s;

.actions-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .9em;

  @keyframes itemAppear {
    0% {
      opacity: 0;
      transform: translate3d(0, .5rem, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  > * {
    opacity: 0;
    animation: itemAppear $animation-time $ease-out-quint forwards;
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: ( .075s * $i) + $appear-delay;
      }
    }
  }
}