@import 'easings.scss';

.content {
  @keyframes contentAppear {
    0% {
      opacity: 0;
      transform: translate3d(0, 1rem, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  opacity: 0;

  animation: contentAppear 2.5s $ease-out-quint forwards;
  animation-delay: .9s;
}

.wysiwyg {
  p {
    margin-bottom: 1.5rem;
  }

  a {
    color: var(--color-primary);
    text-decoration: underline;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  h6 {
    font-size: .75rem;
    margin-bottom: 1.5rem;
  }

  ul {
    margin-bottom: 1.5rem;
  }

  ol {
    margin-bottom: 1.5rem;
  }

  li {
    margin-bottom: .5rem;
  }

  blockquote {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    border-left: 2px solid var(--color-primary);
  }

  img {
    max-width: 100%;
    margin-bottom: 1.5rem;
    border-radius: var(--gap);
  }

  figure {
    margin-bottom: 1.5rem;
  }

  figcaption {
    font-size: .75rem;
    text-align: center;
    margin-top: .5rem;
  }

  table {
    margin-bottom: 1.5rem;
  }

  th {
    font-weight: bold;
  }

  td {
    padding: .5rem;
  }

  tr {
    border-bottom: 1px solid var(--color-primary);
  }

  tr:last-child {
    border-bottom: none;
  }

  code {
    font-family: monospace;
    font-size: .75rem;
    padding: .25rem .5rem;
    background: var(--color-primary);
    color: var(--color-background);
  }

  pre {
    margin-bottom: 1.5rem;
  }

  pre code {
    display: block;
    padding: 1rem;
    background: var(--color-background-dark);
    color: var(--color-primary);
  }

  hr {
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  sup {
    font-size: .75rem;
    vertical-align: super;
  }

  sub {
    font-size: .75rem;
    vertical-align: sub;
  }

  small {
    font-size: .75rem;
  }


}