@import 'easings.scss';
@import "../../../styling/responsive";

.list {
  @keyframes listAppear {
    0% {
      opacity: 0;
      transform: translate3d(0, 4em, 0)
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0)
    }
  }

  opacity: 0;
  animation: listAppear 0.5s $ease-out-quint forwards;
  animation-delay: .5s;

  margin-bottom: calc(var(--gap) * 2);

  &:nth-child(2) {
    animation-delay: .6s;
  }

  &__title {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: var(--gap);
  }
}

.item {
  @keyframes itemAppear {
    0% {
      opacity: 0;
      transform: translate3d(0, 2em, 0)
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0)
    }
  }

  opacity: 0;
  animation: itemAppear 0.5s $ease-out-quint forwards;
  animation-delay: .5s;

  margin-bottom: calc(2 * var(--gap));

  @for $i from 1 through 1000 {
    &:nth-child(#{$i}) {
      animation-delay: (($i) * 0.1s) + .75s;
    }
  }

  &__separator {
    display: block;
    width: 3em;
    height: 1px;
    background: currentColor;
    margin: calc(var(--gap) * .5) 0;
  }

  &__artists,
  &__links {
    margin-bottom: calc(var(--gap) * .5);

    > span {
      &:after {
        content: '-';
        margin: 0 calc(var(--gap) * .25);
      }

      &:last-child:after {
        content: '';
      }

    }

    a {
      color: currentColor;
    }
  }

  &__links {
    margin-top: var(--gap);
    margin-bottom: 0;
  }

  &__arrow {
    display: inline-block;
    width: .5em;
    height: .5em;
    margin-left: calc(var(--gap) * .5);
    border: 1px solid currentColor;
    border-width: 0 1px 1px 0;
    transform: translate3d(-.5em, -.125em, 0) rotate(-45deg);
  }

  &__place {
    //font-style: italic;
  }

  &__name {
    //font-weight: 500;
    // font-style: italic;
  }

  @include tablet {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(var(--gap) * 4);
    margin-top: calc(var(--gap) * 4);

    .item {
      &__main {
        width: 30%;
      }

      &__infos {
        flex-grow: 1;
      }

      &__links {
        display: flex;
        margin: 0;

        .item__arrow {
          display: none;
        }
      }
    }
  }

  &-passed {
    color: var(--color-dark-lighter);
    text-decoration: line-through;
  }
}