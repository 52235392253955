@import 'easings.scss';

$transition-time: 1s;

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &-loaded {
    display: none;
  }
}