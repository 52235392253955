@import "./config/variables";
@import './responsive';

@mixin fixed_grid($columns, $gap) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-gap: $gap;
}

@mixin grid($max-columns: 12, $gutter: $gap) {
  @include mobile {
    > * {
      margin-bottom: $gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include tablet {
    @include fixed_grid(round($max-columns*.5), $gutter);
  }

  @include desktop {
    @include fixed_grid($max-columns, $gutter);
  }
}