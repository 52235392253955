@import "easings.scss";

$transition-time: 1s;
$buttonWidth: 3em;
$buttonHeight: 4em;

.scroll-top {
  display: block;
  position: fixed;
  bottom: 0;
  right: var(--gap);
  cursor: pointer;
  width: $buttonWidth;
  height: $buttonHeight;
  background: var(--color-background-darker);
  color: var(--color-dark-lighter);
  z-index: 10000;
  transform: translate3d(0, 100%, 0);
  border-top-left-radius: $buttonWidth*.25;
  border-top-right-radius: $buttonWidth*.25;

  transition: transform $transition-time $ease-out-quint;

  &:before {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    border: 1px solid currentColor;
    border-width: 0 1px 1px 0;
    transform: rotate(-135deg);
    margin: calc(($buttonHeight - 0.5em) * .5) auto auto;
  }

  &--visible {
    transform: translate3d(0, 0, 0);
  }
}