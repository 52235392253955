$transition-time: .5s;
$hover-size: .25em;

@import "easings.scss";

@import '../../../styling/responsive.scss';

.button {
  cursor: pointer;
  color: var(--color-primary-lighter);
  height: 3em;
  border-radius: 1.5em;
  border: 1px solid currentColor;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5em;
  margin: 0 .5em;
  background: var(--color-background);
  font-weight: normal;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &__icon {
    margin-right: 1em;

    svg {
      fill: currentColor;
      width: 1.25em;
      margin: -.25em;
      height: auto;
    }
  }

  transition: all $transition-time $ease-out-quint;
  box-shadow: 0 0 0 currentColor;

  &:hover {
    color: var(--color-primary-lighter);
    box-shadow: $hover-size $hover-size 0 currentColor;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  @include tablet-max {
    &-no-icon-small {
      .button__icon {
        display: none;
      }
    }

    &-no-label-small {
      .button__label {
        display: none;
      }

      .button__icon {
        margin-right: 0;
      }

      width: 3em;
      height: 3em;
    }
  }
}