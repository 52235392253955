$appear-delay: 0;
$animation-time: 1s;

@import "easings.scss";
@import '../../../../styling/responsive.scss';

.headings {
  color: var(--color-primary);
  margin-bottom: calc(2 * var(--gap));

  @keyframes headingAppear {
    0% {
      opacity: 0;
      transform: translate3d(0, 4rem, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  > * {
    opacity: 0;
    animation: headingAppear $animation-time $ease-out-quint forwards;

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: ( .075s * $i) + $appear-delay;
      }
    }
  }

  &.has-image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.image {
  overflow: hidden;
  width: 100%;
  height: 50vh;
  min-height: 10em;
  max-height: 24em;
  position: relative;

  &:after {
    content: '';
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .3333333) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  &--no-text {
    &:after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
    }
  }

  img {
    border-top-left-radius: var(--gap);
    border-top-right-radius: var(--gap);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.h1 {
  font-size: 2em;
  @include tablet {
    font-size: 3em;
  }
  @include desktop {
    font-size: 4em;
  }
  font-weight: 800;

  .has-image & {
    margin-top: calc(-2 * var(--gap));
  }
}

.p {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: var(--gap);

  .has-image & {
    margin-left: 1em;
  }
}

.cta {
  display: inline-block;
  background: var(--color-primary);
  color: var(--color-light);
  padding: var(--gap) calc(2 * var(--gap));

  &:hover {
    background: var(--color-primary-darker);
    color: var(--color-light);
  }

}