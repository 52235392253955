@import '../../../styling/grid';
@import "easings.scss";

$animation-time: 1s;
$appear-delay: 0;

@keyframes cardAppear {
  0% {
    opacity: 0;
    transform: translate3d(0, 4em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.list {
  @include grid(3, calc(2 * var(--gap)));
}

.card {
  position: relative;
  background: var(--color-primary);
  border-radius: var(--gap);;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 12em;

  opacity: 0;

  animation: cardAppear $animation-time $ease-out-quint forwards;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: ( .075s * $i) + $appear-delay;

      .card__name {
        animation-delay: ( .075s * ($i+1)) + $appear-delay;
      }

      .card__service {
        @for $j from 1 through 5 {
          &:nth-child(#{$j}) {
            animation-delay: ( .075s * (4+$i+$j)) + $appear-delay;
          }
        }
      }
    }
  }

  $hover-size: .5em;
  $hover-color: var(--color-background-darker);

  box-shadow: 0 0 0 $hover-color;
  transition: all $animation-time $ease-out-quint;

  &:hover {
    box-shadow: $hover-size $hover-size 0 $hover-color;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .75;
    //filter: grayscale(100%);
    mix-blend-mode: exclusion;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__name {
    padding: calc(0.333 * var(--gap)) calc(0.5 * var(--gap));
    color: var(--color-light);
    background: var(--color-primary);
    font-weight: 700;
    font-size: 1.5em;
    opacity: 0;
    animation: cardAppear $animation-time $ease-out-quint forwards;
  }

  &__services {
    display: flex;
    margin-top: var(--gap);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__service {
    opacity: 0;
    animation: cardAppear $animation-time $ease-out-quint forwards;
    font-size: .7em;
    color: var(--color-primary);
    margin: calc(0.25 * var(--gap));
    padding: calc(0.25 * var(--gap)) calc(0.5 * var(--gap));
    background: var(--color-light);
    border-radius: calc(0.75 * var(--gap));
  }
}