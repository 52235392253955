@import 'easings.scss';

$transition-time: .5s;

@import '../../../styling/responsive';

.header {
  @include wrapper;

  display: flex;

  &__logo {
    --logo-gap: calc(var(--gap) * 1.25);

    width: 6em;

    @include desktop {
      width: 7em;
    }

    background-color: var(--color-primary);
    padding: var(--logo-gap);
    border-bottom-left-radius: var(--gap);
    border-bottom-right-radius: var(--gap);

    @keyframes logoWrapperAppear {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes logoAppear {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: logoWrapperAppear $transition-time*2 $ease-out-quint forwards;

    img {
      opacity: 0;
      animation: logoAppear $transition-time*3 $ease-out-quint forwards;
      animation-delay: $transition-time*.3;
    }
  }

  &__nav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    @include tablet-max {
      z-index: 100;

      &-list {
        display: flex;
        max-height: 1px;
        overflow: hidden;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        pointer-events: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: max-height 0.00001s;
        transition-delay: 1s;

        &:before {
          content: '';

          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-background-darker);
          transform: translate3d(0, -100%, 0);
          transition: transform $transition-time $ease-in-out-quint;
        }
      }

      &-item {
        overflow: hidden;
        padding: calc(var(--gap) * 0.5);

        a {
          display: block;
          font-size: 2em;
          font-weight: 900;
          color: var(--color-primary);
          text-decoration: none;
          opacity: 0;
          transform: translate3d(0, 100%, 0) skewY(10deg);
          transition: all $transition-time $ease-out-quint;
        }
      }

      &-active {
        .header__nav-list {
          pointer-events: all;
          max-height: 100vh;
          transition-delay: 0s;

          &:before {
            transform: translate3d(0, 0, 0);
          }
        }

        .header__nav-item {
          a {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              a {
                transition-delay: ($transition-time*0.5) + ($i * 0.1s);
              }
            }
          }
        }
      }
    }

    @include tablet {
      &-hamburger {
        display: none;
      }

      &-list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
      }

      &-item {
        height: 100%;
        overflow: hidden;

        @keyframes menuItemAppear {
          0% {
            opacity: 0;
            transform: translate3d(-100%, 0, 0)
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0)
          }
        }

        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        animation: menuItemAppear $transition-time*3 $ease-out-quint forwards;

        $maxItems: 3;
        @for $i from 1 through $maxItems {
          &:nth-child(#{$i}) {
            animation-delay: (($maxItems - $i) * 0.1s);
          }
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          color: var(--color-dark);
          text-decoration: none;
          font-weight: 400;
          font-size: 1.25em;
          padding: var(--gap);
          height: 100%;
          display: flex;
          align-items: center;
          //border-bottom-right-radius: var(--gap);
          //border-bottom-left-radius: var(--gap);

          border-top: 4px solid transparent;

          &.active {
            // background: var(--color-background-dark);
            border-top-color: var(--color-primary);
          }
        }
      }
    }
  }
}