$transition-time: .2s;
$transition-function: ease-in-out;
$button-size: 2.5em;

.hamburger {
  display: block;
  margin-left: auto;
  width: $button-size;
  height: $button-size;
  cursor: pointer;
  color: var(--color-primary);


  @keyframes burgerAppear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  animation: burgerAppear $transition-time*3 $transition-function forwards;
  animation-delay: $transition-time;

  &-box {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &-inner {
    width: 100%;
    height: 3px;
    background-color: currentColor;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all $transition-time $transition-function;


    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 3px;
      background-color: currentColor;
      position: absolute;
      left: 0;
      transition: all $transition-time $transition-function;
    }

    &::before {
      top: -.75em;
    }

    &::after {
      top: .75em;
      width: 50%;
    }
  }

  &:hover {
    .hamburger-inner {
      &::after {
        width: 100%;
      }
    }
  }

  &-active {
    //color: white;

    .hamburger-box {
      .hamburger-inner {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
          width: 100%;
        }
      }
    }
  }
}