@import '../../../styling/responsive.scss';

.text {
  &__separator {
    display: flex;
    align-items: center;

    &:after {
      content: '';
      display: block;
      flex-grow: 1;
      background: currentColor;
      margin-left: var(--gap);
      height: 1px;
    }
  }

  &--extralarge {
    font-size: 2em;

    @include tablet {
      font-size: 2.5em;
    }
  }

  &--large {
    font-size: 1.1em;

    @include tablet {
      font-size: 1.2em;
    }
  }

  &--small {
    font-size: 0.9em;
    @include tablet {
      font-size: 0.8em;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--center {
    text-align: center;
  }

  &--bold {
    font-weight: bold;
  }

  &--extrabold {
    font-weight: 900;
  }

  &--italic {
    font-style: italic;
  }

  &--gutter {
    margin: var(--gap);
    @include tablet {
      margin: var(--gap) calc(var(--gap) * 2);
    }
    @include desktop {
      margin: calc(var(--gap) * 2) calc(var(--gap) * 4);
    }
    @include large-desktop {
      margin: calc(var(--gap) * 3) calc(var(--gap) * 6);
    }
  }
}